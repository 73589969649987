import { Controller } from "stimulus";
import ax from "packs/axios";

export default class extends Controller {
  static targets = ["imperialButton", "metricButton"];

  connect() {
    const usingMetric = this.data.get("usingMetric") == "true";
    usingMetric ? this.activateMetric() : this.activateImperial();

    const loadingController = document.querySelector("#meal-plan-loading")
      .mealPlanLoadingController;

    this.imperialButtonTarget.addEventListener("click", e => {
      this.activateImperial();
      loadingController.updateMeasurementSystem(false);
    });

    this.metricButtonTarget.addEventListener("click", e => {
      this.activateMetric();
      loadingController.updateMeasurementSystem(true);
    });
  }

  activateImperial() {
    this.imperialButtonTarget.className = "btn btn-success";
    this.metricButtonTarget.className = "btn btn-success btn-outline-success";
  }

  activateMetric() {
    this.metricButtonTarget.className = "btn btn-success";
    this.imperialButtonTarget.className = "btn btn-success btn-outline-success";
  }
}

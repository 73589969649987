import { Controller } from "stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = [
    "loader",
    "recommendedLink",
    "notRecommendedLink",
    "description",
    "replacements"
  ];

  hideModal(e) {
    $(this.element).modal("hide");
  }

  connect() {
    this.loadedRecommendedRecipes = false;
    this.loadedNonRecommendedRecipes = false;

    $(this.element).one("shown.bs.modal", e => {
      this.fetchData(true);
    });

    $(this.recommendedLinkTarget).click(() => {
      this.fetchData(true);
      this.descriptionTarget.innerHTML =
        "These recipes can be swapped in while fitting all the meal plan goals (calorie range, time restrictions, and high daily dozen percentage).";
    });
    $(this.notRecommendedLinkTarget).click(() => {
      this.fetchData(false);

      this.descriptionTarget.innerHTML =
        "These recipes don't perfectly fit the meal plan's settings, but you can swap them in to build your own meal plan if you'd like.";
    });
  }

  mealPlanId() {
    return this.data.get("mealPlanId");
  }

  recipeId() {
    return this.data.get("recipeId");
  }

  initCookbookRecipeTooltips() {
    const $tooltipElems = $(this.element).find('[data-toggle="tooltip"]');
    $tooltipElems.tooltip();
  }

  fetchData(recommended) {
    const url = `/meal_plans/${this.mealPlanId()}/replacement_recipes?recipe_id=${this.recipeId()}&recommended=${recommended}`;

    const $replacements = $(this.replacementsTarget);
    $replacements.empty();

    const controller = this;
    const loader = this.loaderTarget;
    loader.hidden = false;
    axios
      .get(url)
      .then(function (response) {
        $replacements.html(response.data);
        controller.initCookbookRecipeTooltips();
      })
      .catch(function (error) {
        $replacements.html("Failed to load recipes");
      })
      .finally(function () {
        loader.hidden = true;
      });
  }
}

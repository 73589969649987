import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "infoLink",
    "imageLink",
    "chartLink",
    "notesLink",
    "recipeFace",
    "ingredientsFace",
    "unhealthyIngredientsFace",
    "unhealthyIngredientFace",
    "imageFace",
    "chartFace",
    "notesFace",
    "tooltip"
  ];

  connect() {
    this.notifyRecipeBrowser();
    this.initTooltips();
    $(this.element).on("shown.bs.tooltip", e => {
      $(this.tooltipTargets).not(e.target).tooltip("hide");
    });
  }

  notifyRecipeBrowser() {
    const browserElement = document.getElementById("recipe-browser");
    if (browserElement) {
      const recipeBrowserController = browserElement.recipeBrowserController;
      recipeBrowserController.addRecipeController(this);
    }
  }

  initTooltips() {
    const $tooltips = $(this.element).find('[data-toggle="tooltip"]');
    $tooltips.tooltip();
  }

  hideCardFaces() {
    this.hideTooltips();
    const cardFaces = this.element.querySelectorAll(".card-face");
    cardFaces.forEach(e => (e.style.display = "none"));
  }

  hasImage() {
    return this.data.get("hasImage") == "true";
  }

  showRecipe() {
    this.hideCardFaces();
    this.recipeFaceTarget.style.display = "block";
    this.infoLinkTarget.classList.add("active");
    if (this.hasImage()) {
      this.imageLinkTarget.classList.remove("active");
    }
    this.chartLinkTarget.classList.remove("active");
    this.notesLinkTarget.classList.remove("active");
  }

  showImage() {
    if (!this.hasImage()) {
      return;
    }
    this.hideCardFaces();
    this.imageFaceTarget.style.display = "flex";
    this.imageLinkTarget.classList.add("active");
    this.infoLinkTarget.classList.remove("active");
    this.chartLinkTarget.classList.remove("active");
    this.notesLinkTarget.classList.remove("active");
  }

  showChart() {
    this.hideCardFaces();
    this.chartFaceTarget.style.display = "block";
  }

  showNotes() {
    this.hideCardFaces();
    this.notesFaceTarget.style.display = "block";
  }

  showIngredients() {
    this.hideCardFaces();
    this.ingredientsFaceTarget.style.display = "block";
  }

  showUnhealthyIngredients() {
    this.hideCardFaces();
    this.unhealthyIngredientsFaceTarget.style.display = "block";
  }

  showUnhealthyIngredient(e) {
    this.hideCardFaces();
    const ingredient = e.target.dataset.ingredient;
    const unhealthyIngredientFace = this.unhealthyIngredientFaceTargets.find(
      face => face.dataset.ingredient == ingredient
    );
    unhealthyIngredientFace.style.display = "block";
  }

  hideTooltips() {
    $(this.tooltipTargets).tooltip("hide");
  }
}

import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['face'];

  hideCardFaces() {
    this.faceTargets.forEach(e => (e.style.display = 'none'));
  }

  showFace(e) {
    this.hideCardFaces();

    const infoIcon = e.target;
    const face = this.faceTargets.find(
      f => f.dataset.face == infoIcon.dataset.face,
    );

    face.style.display = 'block';
  }

  back() {
    this.hideCardFaces();
    this.faceTargets[0].style.display = 'block';
  }
}

import { Controller } from "stimulus";
import ax from "packs/axios";

export default class extends Controller {
  static targets = [];

  connect() {
    const owned = this.data.get("owned");
    if (owned == "true") {
      this.element.checked = true;
    }
  }

  // Optimistically update the cookbooks to the proper state. Assume the
  // server side part works appropriately. Is simple enough and low consequence
  // enough to not worry about error messages etc.
  updateOwnership() {
    const id = this.data.get("id");
    const url = `/cookbooks/${id}/set_ownership`;
    ax.post(url);
  }
}

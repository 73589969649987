import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    window.onbeforeprint = e => {
      this.removePrintClasses();

      if (this.shoppingListOpen()) {
        document.body.classList.add("print_shopping_list");
      } else {
        document.body.classList.add("print_plan");
      }
    };
  }

  removePrintClasses() {
    const classes = ["print_plan", "print_shopping_list"];
    classes.forEach(c => document.body.classList.remove(c));
  }

  shoppingListOpen() {
    const shoppingListModal = document.getElementById("shopping-list-modal");
    return shoppingListModal.classList.contains("show");
  }
}

import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = [];

  connect() {
    const selectSelector = '[data-controller="food-form"] select';
    $(selectSelector).select2();
    $(document).on('cocoon:after-insert', function() {
      $(selectSelector).select2();
    });
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    console.log("connected form controller");
    $(this.element).find("select").select2({ width: "100%" });

    $(document).on("cocoon:after-insert", () => {
      $(this.element).find("select").select2();
    });
  }
}

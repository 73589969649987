import { Controller } from "stimulus";
import noUiSlider from "nouislider";
import wNumb from "wnumb";
import ax from "packs/axios";

export default class extends Controller {
  static targets = [
    "form",
    "infoViewButton",
    "imageViewButton",
    "servings",
    "minServings",
    "maxServings",
    "calories",
    "minCalories",
    "maxCalories",
    "page",
    "entries",
    "loader",
    "finished"
  ];

  initialize() {
    let options = {
      rootMargin: "0px"
    };

    this.intersectionObserver = new IntersectionObserver(
      entries => this.processIntersectionEntries(entries),
      options
    );
  }

  connect() {
    this.element["recipeBrowserController"] = this;
    this.recipeControllers = [];
    this.showImages = false;
    this.noMorePages = false;
    this.setPage(1);
    this.load();

    this.initServingsSlider();
    this.initCalorieSlider();
    this.initViewToggles();
    this.reloadOnChange();

    this.intersectionObserver.observe(this.finishedTarget);
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.finishedTarget);
  }

  addRecipeController(recipeController) {
    this.recipeControllers.push(recipeController);
    if (this.showImages) {
      recipeController.showImage();
    }
  }

  setPage(page) {
    this.pageTarget.value = page;
  }

  reloadOnChange() {
    const $selects = $(this.element).find("select");
    $selects.on("change", e => this.reload(e));

    const sliders = [this.servingsTarget, this.caloriesTarget];
    sliders.forEach(s => s.noUiSlider.on("set", () => this.reload()));
  }

  initServingsSlider() {
    const slider = this.servingsTarget;

    noUiSlider.create(slider, {
      start: [1, 8],
      step: 1,
      connect: true,
      range: {
        min: 1,
        max: 8
      },
      pips: {
        mode: "steps",
        density: 12.5
      },
      tooltips: true,
      format: wNumb({ decimals: 0 })
    });

    const controller = this;
    slider.noUiSlider.on("set", function () {
      const [minServings, maxServings] = this.get();
      controller.minServingsTarget.value = minServings;
      controller.maxServingsTarget.value = maxServings;
    });
  }

  initCalorieSlider() {
    const slider = this.caloriesTarget;

    noUiSlider.create(slider, {
      start: [200, 1400],
      step: 100,
      connect: true,
      range: {
        min: 200,
        max: 1400
      },
      margin: 100,
      pips: {
        mode: "steps"
      },
      tooltips: true,
      format: wNumb({ decimals: 0 })
    });

    const controller = this;
    slider.noUiSlider.on("set", function () {
      const [minCalories, maxCalories] = this.get();
      controller.minCaloriesTarget.value = minCalories;
      controller.maxCaloriesTarget.value = maxCalories;
    });
  }

  initViewToggles() {
    this.infoViewButtonTarget.addEventListener("click", e =>
      this.activateInfoView()
    );

    this.imageViewButtonTarget.addEventListener("click", e =>
      this.activateImageView()
    );
  }

  activateInfoView() {
    this.showImages = false;
    this.infoViewButtonTarget.className = "btn btn-success";
    this.imageViewButtonTarget.className =
      "btn btn-success btn-outline-success";
    this.recipeControllers.forEach(r => r.showRecipe());
  }

  activateImageView() {
    this.showImages = true;
    this.imageViewButtonTarget.className = "btn btn-success";
    this.infoViewButtonTarget.className = "btn btn-success btn-outline-success";
    this.recipeControllers.forEach(r => r.showImage());
  }

  reload(e) {
    this.finishedTarget.style.opacity = "0.0";
    this.noMorePages = false;
    this.setPage(1);
    this.emptyRecipes();
    this.load();
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadNextPage();
      }
    });
  }

  load() {
    this.loading = true;
    this.loaderTarget.hidden = false;
    Rails.fire(this.formTarget, "submit");
  }

  loadNextPage() {
    if (this.noMorePages || this.loading) {
      return;
    }
    const nextPage = parseInt(this.pageTarget.value) + 1;
    this.setPage(nextPage);
    this.load();
  }

  loadComplete(e) {
    this.loading = false;
    this.loaderTarget.hidden = true;
  }

  emptyRecipes() {
    this.entriesTarget.innerHTML = "";
    this.recipeControllers = [];
  }

  appendRecipes(recipes) {
    this.entriesTarget.insertAdjacentHTML("beforeend", recipes);

    if (this.showImages) {
      this.recipeControllers.forEach(c => c.showImage());
    }
  }

  finishedLoadingPages() {
    this.noMorePages = true;
  }

  showFinishedMessage() {
    this.finishedTarget.style.opacity = "1.0";
  }

  hideFinishedMessage() {
    this.finishedTarget.style.opacity = "0.0";
  }
}

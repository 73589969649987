import { Controller } from "stimulus";
import ax from "packs/axios";

export default class extends Controller {
  static targets = ["checkoutButton"];

  connect() {
    this.checkoutButtonTarget.addEventListener("click", () => {
      this.createCheckoutSessionAndRedirectToIt();
    });
  }

  createCheckoutSessionAndRedirectToIt() {
    const publishableKey = this.data.get("publishableKey");
    const stripe = Stripe(publishableKey);
    const planId = this.data.get("planId");
    const checkoutSessionUrl = `${origin}/stripe/checkout_session?plan=${planId}`;

    ax.get(checkoutSessionUrl).then(function (response) {
      const checkoutSessionId = response.data;
      return stripe.redirectToCheckout({ sessionId: checkoutSessionId });
    });
  }
}

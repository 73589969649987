import { Controller } from "stimulus";
import ax from "packs/axios";
import debounce from "debounce";

export default class extends Controller {
  static targets = [];

  update() {
    const recipeId = this.data.get("recipeId");
    const currentNote = this.element.value;
    const url = `/recipes/${recipeId}/note?note=${encodeURIComponent(
      currentNote
    )}`;
    ax.post(url);
  }
}

import {Controller} from 'stimulus';
export default class extends Controller {
  get enabled() {
    return JSON.parse(this.data.get('enabled'));
  }

  connect() {
    if (!this.enabled) {
      this.disableLink();
    }
  }

  disableLink() {
    this.element.classList.add('disabled');
    this.element.addEventListener('click', e => e.preventDefault());
  }
}

import { Controller } from "stimulus";
import noUiSlider from "nouislider";
import wNumb from "wnumb";

export default class extends Controller {
  static targets = [
    "mealPlan",
    "numberDaysInput",
    "planTypeExplanation",
    "daysExplanation",
    "sameBreakfast",
    "hasSnacks",
    "calorieMin",
    "calorieMax",
    "mealsCalorieSlider",
    "calorieMinSnacks",
    "calorieMaxSnacks",
    "snackSection",
    "snacksCalorieSlider",
    "totalCalories"
  ];

  connect() {
    this.initMealsCaloriesSlider();
    this.initSnacksCaloriesSlider();
    this.toggleSnacksSection();
    this.listenForSnackToggle();
    this.updateTotalCalories();
  }

  initMealsCaloriesSlider(isPremium) {
    const slider = this.mealsCalorieSliderTarget;

    noUiSlider.create(slider, {
      start: [this.calorieMinTarget.value, this.calorieMaxTarget.value],
      step: 100,
      connect: true,
      range: {
        min: 800,
        max: 3000
      },
      margin: 300,
      pips: {
        mode: "steps"
      },
      tooltips: true,
      format: wNumb({ decimals: 0 })
    });

    const calorieMinTarget = this.calorieMinTarget;
    const calorieMaxTarget = this.calorieMaxTarget;
    slider.noUiSlider.set([calorieMinTarget.value, calorieMaxTarget.value]);

    const controller = this;
    slider.noUiSlider.on("slide", function () {
      const values = this.get();
      calorieMinTarget.value = values[0];
      calorieMaxTarget.value = values[1];
      controller.updateTotalCalories();
    });
  }

  initSnacksCaloriesSlider(isPremium) {
    const slider = this.snacksCalorieSliderTarget;

    noUiSlider.create(slider, {
      start: [
        this.calorieMinSnacksTarget.value,
        this.calorieMaxSnacksTarget.value
      ],
      behaviour: "drag-fixed",
      connect: true,
      step: 100,
      range: {
        min: 100,
        max: 1000
      },
      margin: 200,
      pips: {
        mode: "steps"
      },
      tooltips: true,
      format: wNumb({ decimals: 0 })
    });

    const calorieMinTarget = this.calorieMinSnacksTarget;
    const calorieMaxTarget = this.calorieMaxSnacksTarget;
    slider.noUiSlider.set([calorieMinTarget.value, calorieMaxTarget.value]);

    const controller = this;
    slider.noUiSlider.on("slide", function () {
      const values = this.get();
      calorieMinTarget.value = values[0];
      calorieMaxTarget.value = values[1];
      controller.updateTotalCalories();
    });
  }

  toggleSnacksSection() {
    const checked = this.hasSnacksTarget.checked;
    const display = checked ? "block" : "none";
    this.snackSectionTarget.style.display = display;
  }

  listenForSnackToggle() {
    const controller = this;
    this.hasSnacksTarget.addEventListener("change", () => {
      controller.toggleSnacksSection();
    });
  }

  updateTotalCalories() {
    const mealValues = this.mealsCalorieSliderTarget.noUiSlider.get();
    const mealsMin = parseInt(mealValues[0]);
    const mealsMax = parseInt(mealValues[1]);

    const snackValues = this.snacksCalorieSliderTarget.noUiSlider.get();
    const snacksMin = parseInt(snackValues[0]);
    const snacksMax = parseInt(snackValues[1]);

    const min = mealsMin + snacksMin;
    const max = mealsMax + snacksMax;
    this.totalCaloriesTarget.innerHTML = `Total Calories: ${min} - ${max}`;
  }

  handleTypeChange(e) {
    const mealPlanType = e.target.value;
    if (mealPlanType == "MinimalistMealPlan") {
      this.changeToMinimalistMealPlan();
    } else if (mealPlanType == "DailyCookMealPlan") {
      this.changeToDailyCookMealPlan();
    } else {
      this.resetOptions();
    }
  }

  changeToMinimalistMealPlan() {
    this.sameBreakfastTarget.checked = true;
    this.sameBreakfastTarget.classList.add("disabled");
    this.planTypeExplanationTarget.innerHTML =
      "Cook everything in a single prep day. 3 total recipes, eat a serving of each per day.";
    this.numberDaysInputTarget.max = "7";
    this.numberDaysInputTarget.oninvalid = () =>
      this.numberDaysInputTarget.setCustomValidity(
        "A Minimalist Meal Plan can only have a maximum of 7 days. The intent is for this to be a single shopping trip, so I had to set the limit somewhere! "
      );
    this.numberDaysInputTarget.oninput = () =>
      this.numberDaysInputTarget.setCustomValidity("");

    // this.numberDaysInputTarget.onchange = e => {
    //   if (e.target.value > 7) {
    //     this.daysExplanationTarget.innerHTML =
    //       "Be sure the recipes are freezable if you're planning > 7 days!";
    //   } else {
    //     this.daysExplanationTarget.innerHTML = '';
    //   }
    // };
  }

  changeToDailyCookMealPlan() {
    this.sameBreakfastTarget.disabled = false;
    this.sameBreakfastTarget.classList.remove("disabled");
    this.planTypeExplanationTarget.innerHTML =
      "Cook a new recipe each evening, eat a serving for <b>dinner</b> and then another for <b>lunch</b> the following day.";
    this.numberDaysInputTarget.max = "7";
    this.numberDaysInputTarget.oninvalid = () =>
      this.numberDaysInputTarget.setCustomValidity(
        "A Daily Cook Meal Plan can only have a maximum of 7 days. The intent is for this to be a single shopping trip, so I had to set the limit somewhere!"
      );
    this.numberDaysInputTarget.oninput = () =>
      this.numberDaysInputTarget.setCustomValidity("");

    this.daysExplanationTarget.innerHTML = "";
    this.daysExplanationTarget.onchange = null;
  }

  resetOptions() {
    this.sameBreakfastTarget.disabled = false;
    this.sameBreakfastTarget.classList.remove("disabled");
    this.planTypeExplanationTarget.innerHTML = "";
    this.daysExplanationTarget.innerHTML = "";
    this.daysExplanationTarget.onchange = null;
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  initialize() {
    this.boundHideWhenClickingOutsidePopOver = this.hideWhenClickingOutsidePopOver.bind(
      this
    );
  }

  connect() {
    this.initPopOver();
    const $popOver = $(this.element);
    $popOver.click(e => $popOver.popover("toggle"));
    $popOver.on("show.bs.popover", e => this.hideOtherPopOvers());

    document.addEventListener(
      "click",
      this.boundHideWhenClickingOutsidePopOver
    );
  }

  disconnect() {
    document.removeEventListener(
      "click",
      this.boundHideWhenClickingOutsidePopOver
    );

    $(this.popOverTarget).popover("dispose");
  }

  hideWhenClickingOutsidePopOver(e) {
    const isPopOverToggle =
      $(e.target).data("controller") == "nutrient-summary";
    const isPopOver = $(e.target).parents(".popover").length > 0;
    if (!isPopOverToggle && !isPopOver) {
      this.hidePopOver();
    }
  }

  initPopOver() {
    $(this.element).popover({
      html: true,
      template: `
        <div class="popover nutrient-summary-popover" role="tooltip">
          <div class="arrow"></div>
          <div class="popover-body"></div>
        </div>
      `,
      delay: { show: 0, hide: 0 },
      trigger: "manual"
    });
  }

  hideOtherPopOvers() {
    $(".nutrition-facts-button").not(this.element).popover("hide");
  }

  hidePopOver() {
    $(this.element).popover("hide");
  }
}

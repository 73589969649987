import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['error'];

  successfulSubmission(event) {
    let [data, status, xhr] = event.detail;
    this.element.innerHTML = data;
  }

  failedSubmission(event) {
    let [data, status, xhr] = event.detail;
    this.errorTarget.innerHTML = `
      <div class="alert alert-danger">
        <strong>Oops!</strong>
        ${data}
      </div>`;
  }
}

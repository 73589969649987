import { Controller } from "stimulus";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";

export default class extends Controller {
  static targets = ["phone"];

  connect() {
    intlTelInput(this.phoneTarget, {
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.min.js",
      // Automatically submits user[phone] via hidden field in full
      // international format
      hiddenInput: "phone"
    });
  }
}

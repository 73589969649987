import {Controller} from 'stimulus';
import Request from 'axios-request-handler';

export default class extends Controller {
  static targets = [];

  connect() {
    this.pollForPaymentConfirmation();
  }

  pollForPaymentConfirmation() {
    const origin = window.location.origin;
    const hasPaidPollUrl = `${origin}/stripe/has_paid`;
    const hasPaid = new Request(hasPaidPollUrl);
    const mealPlansUrl = `${origin}/meal_plans`;

    hasPaid.poll(250).get(response => {
      const paid = response.data.paid;

      if (paid) {
        window.location.replace(mealPlansUrl);
      }
    });
  }
}

import { Controller } from "stimulus";
import ax from "packs/axios";

export default class extends Controller {
  static targets = [
    "loadingNotice",
    "loader",
    "sameBreakfast",
    "optionsForm",
    "scaleButton",
    "generateButton"
  ];

  connect() {
    this.element.mealPlanLoadingController = this;

    const generated = this.wasGenerated();
    if (generated) {
      this.setOptionFormIdParam();
      this.showScaleButton();
    }
  }

  isPremium() {
    return this.data.get("premium") == "true";
  }

  mealPlanContainer() {
    return document.querySelector("#meal-plan");
  }

  beginGeneration() {
    this.beginLoad();
  }

  successfulGeneration(e) {
    let [data, status, xhr] = e.detail;
    this.successfullLoad(xhr.response, true);
  }

  failedGeneration(e) {
    let [data, status, xhr] = e.detail;
    this.failedLoad(data);
  }

  completedGeneration(e) {
    this.completedLoad();
  }

  scalePlan() {
    const id = document.querySelector("#id-input").value;
    const people = document.querySelector('[name="options[people]"]').value;
    const scaleUrl = `/meal_plans/${id}/scale_plan?people=${people}`;

    const controller = this;
    controller.beginLoad();
    ax.post(scaleUrl)
      .then(function (response) {
        controller.successfullLoad(response.data, true);
        controller.loadingNoticeTarget.innerHTML = `<div class="alert alert-success">
          Scaled plan to fit ${people} people
          <a class="close" data-dismiss="alert" href="#">&times;</a>
          </div>`;
        controller.loadingNoticeTarget.hidden = false;
        window.setTimeout(
          () => (controller.loadingNoticeTarget.hidden = true),
          3000
        );
      })
      .catch(function (error) {
        controller.failedLoad("Something went wrong scaling the meal plan");
      })
      .finally(function () {
        controller.completedLoad();
      });
  }

  updateMeasurementSystem(usingMetric) {
    const controller = this;
    let url = `/meal_plans/change_measurement_system?using_metric=${usingMetric}`;

    const wasGenerated = this.wasGenerated();
    if (wasGenerated) {
      const id = document.querySelector("#id-input").value;
      url += `&id=${id}`;
      controller.beginLoad();
    }

    ax.post(url)
      .then(function (response) {
        controller.successfullLoad(response.data, false);
      })
      .catch(function (error) {
        controller.failedLoad(
          "Something went wrong changing the measurement system"
        );
      })
      .finally(function () {
        controller.completedLoad();
      });
  }

  beginLoad() {
    this.loadingNoticeTarget.hidden = true;
    this.loaderTarget.hidden = false;
    this.mealPlanContainer().hidden = true;
    this.generateButtonTarget.disabled = true;
  }

  successfullLoad(response, shouldScroll) {
    this.mealPlanContainer().innerHTML = response;
    this.mealPlanContainer().hidden = false;
    this.loaderTarget.hidden = true;
    this.setOptionFormIdParam();
    this.showScaleButton();
    if (shouldScroll) {
      this.scrollToPlan();
    }
  }

  failedLoad(errorMessage) {
    this.loaderTarget.hidden = true;
    this.loadingNoticeTarget.innerHTML = `
      <div class="alert alert-danger">
        <strong>Oops!</strong>
        ${errorMessage}
        <a class="close" data-dismiss="alert" href="#">&times;</a>
      </div>`;
    this.loadingNoticeTarget.hidden = false;
    this.mealPlanContainer().hidden = false;
  }

  completedLoad() {
    this.initTooltips();
    if (this.isPremium()) {
      this.generateButtonTarget.disabled = false;
    }
  }

  initTooltips() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  wasGenerated() {
    const idInput = document.querySelector("#id-input");
    return idInput != null;
  }

  setOptionFormIdParam() {
    const idInput = document.querySelector("#id-input");
    if (idInput == null) {
      return;
    }

    const id = idInput.value;
    $(this.optionsFormTarget).append(`
      <input
        type="hidden"
        name="id"
        value="${id}"/>`);
  }

  showScaleButton() {
    this.scaleButtonTarget.classList.remove("disabled");
  }

  scrollToPlan() {
    const plan = document.getElementById("meal-plan");
    plan.scrollIntoView();
  }
}

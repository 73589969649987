import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = [];

  get shareUrl() {
    return this.data.get('shareUrl');
  }

  // Temporary input placed off screen is required,
  // it does not work using a hidden input.
  // Tested in Chrome, Firefox, Safari.
  copyShareUrlToClipboard(e) {
    const tempInput = document.createElement('input');
    tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
    tempInput.value = this.data.get('shareUrl');
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.hideBannerWhenModalDoesNotFitInViewPort();
    this.setBodyPaddingToStopOverlap();
    window.addEventListener("resize", () => this.setBodyPaddingToStopOverlap());
  }

  setBodyPaddingToStopOverlap() {
    const height = this.element.getBoundingClientRect().height;
    const body = document.getElementsByTagName("body")[0];
    body.style.paddingBottom = `${height}px`;
  }

  hideBannerWhenModalDoesNotFitInViewPort() {
    $(document).on("shown.bs.modal", e => {
      const $modalDialog = $(e.target).find(".modal-dialog");
      const modalHeight = $modalDialog.height();
      const windowHeight = window.innerHeight;
      const bannerHeight = $(this.element).height();

      const modalFitsInView = modalHeight < windowHeight - bannerHeight;
      if (!modalFitsInView) {
        this.element.hidden = true;
      }
    });

    $(document).on("hidden.bs.modal", e => {
      this.element.hidden = false;
    });
  }
}

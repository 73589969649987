import {Controller} from 'stimulus';
import axios from 'axios';

export default class extends Controller {
  static targets = ['loader', 'modalFace'];

  connect() {
    $(this.element).one('shown.bs.modal', e => {
      this.fetchData();
    });

    $(this.element).on('shown.bs.modal', e => {
      this.hidePopOvers();
    });

    $(this.element).on('hide.bs.modal', e => this.resetModal());
  }

  fetchData() {
    const url = `/meal_plans/nutrient_details/?type=${this.type()}&id=${this.typeId()}`;
    const $modalContent = $(this.element).find('.modal-content');
    const loader = this.loaderTarget;
    loader.hidden = false;

    axios
      .get(url)
      .then(function(response) {
        $modalContent.append(response.data);
      })
      .catch(function(error) {
        $modalContent.append('Failed to load nutrient data');
      })
      .finally(function() {
        loader.hidden = true;
      });
  }

  type() {
    return this.data.get('type');
  }

  typeId() {
    return this.data.get('typeId');
  }

  hidePopOvers() {
    $('.nutrition-facts-button').popover('hide');
  }

  hideModalFaces() {
    this.modalFaceTargets.forEach(e => (e.style.display = 'none'));
  }

  showModalFace(e) {
    this.hideModalFaces();

    const infoIcon = e.target;
    const face = this.modalFaceTargets.find(
      f => f.dataset.modalFace == infoIcon.dataset.modalFace,
    );

    face.style.display = 'block';
  }

  resetModal() {
    this.hideModalFaces();
    this.modalFaceTargets[0].style.display = 'block';
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "monthlyButton",
    "yearlyButton",
    "monthlySection",
    "yearlySection"
  ];

  connect() {
    this.activateYearly();
    this.monthlyButtonTarget.addEventListener("click", e =>
      this.activateMonthly()
    );
    this.yearlyButtonTarget.addEventListener("click", e =>
      this.activateYearly()
    );
  }

  activateMonthly() {
    this.yearlySectionTarget.style.display = "none";
    this.monthlySectionTarget.style.display = "block";
    this.monthlyButtonTarget.className = "btn btn-success";
    this.yearlyButtonTarget.className = "btn btn-success btn-outline-success";
  }

  activateYearly() {
    this.yearlySectionTarget.style.display = "block";
    this.monthlySectionTarget.style.display = "none";
    this.yearlyButtonTarget.className = "btn btn-success";
    this.monthlyButtonTarget.className = "btn btn-success btn-outline-success";
  }
}

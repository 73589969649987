import { Controller } from "stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = [];

  connect() {
    const $replacementsList = $(".replacements-list:visible");
    const $loader = $replacementsList.prev(".replace-recipe-loader");

    const $pageLinks = $(this.element).find("a");
    $pageLinks.on("click", () => {
      $replacementsList.empty();
      $loader.attr("hidden", false);
    });
  }
}

import {Controller} from 'stimulus';
import {Chart} from 'chart.js';

export default class extends Controller {
  connect() {
    this.buildChart();
  }

  get chartData() {
    return JSON.parse(this.data.get('data'));
  }

  get chartLabels() {
    return JSON.parse(this.data.get('labels'));
  }

  get chartServingFractions() {
    return JSON.parse(this.data.get('servingFractions'));
  }

  buildChart() {
    const data = {
      labels: this.chartLabels,
      datasets: [
        {
          label: 'Percent Satisfied',
          fill: true,
          backgroundColor: '#81A2D0',
          borderColor: 'rgba(66,165,245,1)',
          pointBackgroundColor: 'rgba(66,165,245,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(66,165,245,1)',
          data: this.chartData,
        },
      ],
    };

    const chartServingFractions = this.chartServingFractions;
    const options = {
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            return `Percent Satisfied: ${tooltipItem.yLabel}%`;
          },
          footer: function(tooltipItems, data) {
            const servingFraction =
              chartServingFractions[tooltipItems[0].index];
            return `(${servingFraction} daily servings)`;
          },
        },
      },
      scale: {
        ticks: {
          beginAtZero: true,
          max: 100,
        },
      },
      responsive: true,
    };

    this.chart = new Chart(this.element, {
      type: 'radar',
      data: data,
      options: options,
    });
  }

}

import { Controller } from "stimulus";
import ax from "packs/axios";

export default class extends Controller {
  static targets = [];

  connect() {
    this.verdict = this.data.get("verdict");
  }

  nextState() {
    const verdict = this.verdict;
    if (verdict == "undecided") {
      return "liked";
    } else if (verdict == "liked") {
      return "disliked";
    } else if (verdict == "disliked") {
      return "undecided";
    }
  }

  updateIcon() {
    if (this.verdict == "undecided") {
      this.element.classList.remove("liked-thumb", "disliked-thumb");
      this.element.classList.add("undecided-thumb");
    } else if (this.verdict == "liked") {
      this.element.classList.remove("undecided-thumb", "disliked-thumb");
      this.element.classList.add("liked-thumb");
    } else if (this.verdict == "disliked") {
      this.element.classList.remove("undecided-thumb", "liked-thumb");
      this.element.classList.add("disliked-thumb");
    }
  }

  // Optimistically update the client UI. Low consequence enough to not worry
  // about error messages, etc.
  update() {
    this.verdict = this.nextState();
    this.updateIcon();
    const recipeId = this.data.get("recipeId");
    const url = `/recipes/${recipeId}/review?verdict=${this.verdict}`;
    ax.post(url);
  }
}
